import { graphql } from 'gatsby';
import React, { useState } from "react";
import styled from "styled-components";
import { Box, Button, Collapsible, Heading, Grommet, Paragraph, ResponsiveContext } from 'grommet';
import LayoutApp from '../components/LayoutApp';
import SiteMeta from '../components/SiteMeta';
import { proedgeTheme } from '../grommetui/grommet/Theme';

const metaTitle = 'Enterprise Upskilling Quiz | ProEdge';
const metaDesc = 'How do you know if upskilling is an investment you should prioritize? Is your company on a path to generate value from digital skills development? Answer these six questions to find out where you stand.';


export default function({ data }) {
  const [showSidebar, setShowSidebar] = useState(false);
  const [show, setShow] = useState();
  const {
    headerLogo,
    site: { siteMetadata },
  } = data;
  
  return (
    <LayoutApp headerLogo={headerLogo} navMenu={siteMetadata.navMenu}>
      <SiteMeta title={metaTitle} description={metaDesc} />
      <Grommet 
        theme={proedgeTheme} 
        style={{backgroundImage:'url(/img/quiz-hero-bg.jpg)', backgroundSize: 'cover', backgroundPosition: 'center'}} 
        background='dark-5'
        >
        <ResponsiveContext.Consumer>
          {(size) => {
          const isSmall = size === 'small';
          const isMedium = size === 'medium';
          const isXlarge = size === 'xlarge';
          return (
              <>
                <Box style={{backgroundColor: 'rgba(0,0,0,.6)' }}>
                <Box 
                  pad = {{ 
                    horizontal: isSmall ? '40px' : '80px'}} 
                  style={{
                    maxWidth: '680px',
                    marginLeft: isSmall || isMedium ? '0px' : '120px', 
                    backgroundColor: 'rgba(0,0,0,.7)'
                  }}>
                  <Box 
                    margin= {{
                      vertical: '80px'
                    }}
                    direction="column">
                    <Heading level='1' color="#ffb600" margin='none' style={{paddingBottom: '20px'}} >Disruption is real and the workforce has changed.</Heading>
                    <Heading level='3' margin='none' style={{paddingBottom: '20px'}} >Preparing the workforce for the future will require commitment, but the cost of inaction will be even higher.</Heading>
                    <Paragraph color="#FFFFFF" style={{maxWidth:'inherit', marginBottom: '40px'}}>How do you know if upskilling is an investment you should prioritize? Is your company on a path to generate value from digital skills development?</Paragraph>
                      <Button 
                        label="Answer these six questions to find out where you stand" 
                        href='/enterprise-upskilling-quiz-app'
                        background="#d04a02"
                        style={{color: '#FFFFFF', maxWidth: '320px', border: 'none', borderRadius: '0', paddingTop: '20px', paddingBottom: '20px'}}
                        onClick={() => {
                          if (window.dataLayer) window.dataLayer.push({ event: 'quizappstart-step-letsgo' });
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </>
            )
          }}
          </ResponsiveContext.Consumer>
      </Grommet>
    </LayoutApp>
  );
};

export const query = graphql`
  query {
    headerLogo: file(relativePath: { eq: "logo-gray.png" }) {
      childImageSharp {
        fluid(maxWidth: 250, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        navMenu {
          title
          href
          items {
            description
            href
            title
          }
        }
      }
    }
  }
`;
